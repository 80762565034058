import type { VFC } from 'react';
import { memo } from 'react';
import cn from 'classnames';
import { PageFooter as GlobalFooter } from '@els/biomed-ui';

import styles from './Footer.module.scss';

// Special class name which allows OneTrust to attach event listener automatically.
const oneTrustSettings = 'ot-sdk-show-settings';

export const Footer: VFC = memo(() => {
  const copyright =
    'Elsevier B.V., its licensors, and contributors. All rights are reserved, including those for text and data mining, AI training, and similar technologies.';
  return (
    <GlobalFooter
      links={[
        {
          label: 'Cookie settings',
          className: cn(oneTrustSettings, styles.cookieSettings),
        },
      ]}
      copyright={copyright}
    />
  );
});

export default Footer;
