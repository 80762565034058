// Need to refactor this as we implement new landing page for
// different variants(e.g. No access, homepage to access different features)
//even need to think of appropriate routing path

import type { FunctionComponent } from 'react';
import { Button, Col, Grid, Link, Row } from '@els/biomed-ui';

import { contactUsUrl } from 'constants/constant';
import useAuthData from 'hooks/useAuthData';

import styles from './LandingPage.module.scss';

const LandingPage: FunctionComponent = () => {
  const { signinRedirect, isRegistered, isAuthorized } = useAuthData();

  return (
    <Grid className={styles.root}>
      <Row>
        <Col xs sm={18}>
          <h1 className={styles.header}>
            Welcome to EmBiology:
            <br />
            Unparalleled insight into biological activities
          </h1>
          <p className='mb-4'>
            Visualize millions of biological relationships to gain a fast understanding of disease
            biology, how to modulate the disease process and how to interpret experimental results.
          </p>
          {!isRegistered && (
            <Button
              className={styles.signInButton}
              onClick={() =>
                signinRedirect({
                  extraQueryParams: {
                    connection: '',
                  },
                })
              }
            >
              Sign in
            </Button>
          )}
          {isRegistered && !isAuthorized && (
            <p className='font-weight-bold'>
              You do not have access to EmBiology. Please{' '}
              <Link href={contactUsUrl} external colored>
                Contact us
              </Link>
            </p>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export default LandingPage;
