import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import globalAxios, { isAxiosError, isCancel } from 'axios';

import {
  networkErrorMessage,
  statusErrorMapping,
  tokenKey,
  unknownError,
} from 'constants/constant';
import type { MessageBoxConfig } from 'containers/MessageBox';
import { getLocalData } from 'utils/storage';

export interface ErrorMessageConfig extends Omit<MessageBoxConfig, 'onConfirm' | 'onAlternative'> {}

export interface ApiError extends ErrorMessageConfig {
  message: string;
  status?: number;
}

/**
 * Custom instance of axios object, which adds auth token for each request.
 */
const axios = globalAxios.create({});

axios.interceptors.request.use(config => {
  const token = getLocalData(tokenKey);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

/**
 * Use instance from default export if you want either:
 * - have access to the full response object (with status etc.)
 * - OR want to parse response error by your own.
 *
 * Otherwise, just use shortcuts below - get, post etc.
 */
export default axios;

export const get = <T = unknown, D = unknown>(url: string, config?: AxiosRequestConfig<D>) =>
  axios.get<T, AxiosResponse<T, D>, D>(url, config).then(extractData).catch(rejectWithError);

export const post = <T = unknown, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
) =>
  axios.post<T, AxiosResponse<T, D>, D>(url, data, config).then(extractData).catch(rejectWithError);

export const put = <T = unknown, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
) =>
  axios.put<T, AxiosResponse<T, D>, D>(url, data, config).then(extractData).catch(rejectWithError);

export const remove = <T = unknown, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
) =>
  axios
    .delete<T, AxiosResponse<T, D>, D>(url, { data, ...config })
    .then(extractData)
    .catch(rejectWithError);

function extractData<T = unknown, D = unknown>(response: AxiosResponse<T, D>) {
  return response.data;
}

function formatError(error: unknown): ApiError {
  // It can be any JS error actually, not only axios, so need to check
  if (!isAxiosError(error)) {
    console.warn(error);
    return { ...unknownError };
  }

  const { request, response, message } = error;

  if (message === 'canceled' || isCancel(error)) {
    console.debug('API request is aborted.');
    return { message: 'canceled' };
  }

  if (response) {
    const status = response.status;
    const error = statusErrorMapping[status] || unknownError;
    const header = error.header;
    const supportLink = error.supportLink;
    const signInLink = error.signInLink;
    const message = error.message || response.statusText;

    return { message, supportLink, signInLink, header };
  } else if (request) {
    console.debug(request);
    // If the request was made but no response was received,
    // the user is likely offline.
    return { ...networkErrorMessage };
  } else {
    return { ...unknownError };
  }
}

function rejectWithError(error: unknown) {
  const apiError = formatError(error);
  return Promise.reject(apiError);
}
