import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { createContext, useState } from 'react';
import type { ConfirmDialogInstance } from '@els/biomed-ui';

import type { BaseMessageBoxProps, MessageBoxType } from './MessageBox';
import { MessageBox } from './MessageBox';

type Instance = ConfirmDialogInstance & { hide: () => void };
type ActionHandler = (instance: Instance) => boolean | unknown | Promise<boolean | unknown>;

export type MessageBoxConfig = BaseMessageBoxProps & {
  type?: MessageBoxType;
  onConfirm?: ActionHandler;
  onAlternative?: ActionHandler;
};

interface State {
  // Presence of 'message' in the state controls modal visibility.
  message?: ReactNode;
  config?: MessageBoxConfig;
}

interface Context {
  show: (message: ReactNode, config?: MessageBoxConfig) => void;
  hide: () => void;
}

export const MessageBoxContext = createContext<Context | undefined>(undefined);

export const MessageBoxProvider: FC = ({ children }) => {
  const [{ message, config }, setState] = useState<State>({});

  const { onConfirm, onAlternative, ...otherConfig } = config || {};

  const api = useMemo<Context>(
    () => ({
      show: (message: ReactNode, config?: MessageBoxConfig) => setState({ message, config }),
      hide: () => setState({}),
    }),
    []
  );

  const createHandler =
    (handleFromProps?: ActionHandler) => async (instance: ConfirmDialogInstance) => {
      const result = await handleFromProps?.({ hide: api.hide, ...instance });
      if (result !== false) {
        api.hide();
      }
    };

  return (
    <MessageBoxContext.Provider value={api}>
      {children}
      {message && (
        <MessageBox
          id='message-box'
          message={message}
          onConfirm={createHandler(onConfirm)}
          onAlternative={createHandler(onAlternative)}
          onClose={api.hide}
          {...otherConfig}
        />
      )}
    </MessageBoxContext.Provider>
  );
};
