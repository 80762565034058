import { pageInfo, visitorInfo } from 'constants/env';
import type { EventName, PageName, TrackingData, VisitorInfo } from './model';

export * from './model';

/**
 * Init Adobe Analytics
 */
export const initAdobeAnalytics = (
  visitorInfo: VisitorInfo,
  opt: { noTracking?: boolean } = {}
): void => {
  const { appData = [] } = window;

  if (
    // Check if allowed tracking from fences
    !opt.noTracking
  ) {
    // Note that pageData is used as indicator of initialized/started tracking.
    if (appData && !!appData.length) {
      setVisitor(visitorInfo);
    } else {
      startTracking(visitorInfo);
    }
  } else {
    window.appData = window.appData || [];
    window.pageData = undefined;
  }
};

/**
 * Track new page event
 */
export const trackNewPage = (page: PageName, data?: TrackingData): void =>
  trackEvent('newPage', {
    page: { name: page },
    ...data,
  });

/**
 * Track feature event
 */
export const trackProductFeatureUsed = (name: string) =>
  trackEvent('productFeatureUsed', { feature: { name } });

/**
 * Starts tracking (dispatch page load event)
 */
const startTracking = (visitor: VisitorInfo) => {
  window.appData = window.appData || [];

  // It is important to set pageData into window before trackPageLoad
  const eventData: TrackingData = {
    page: {
      ...pageInfo,
      name: 'page-init',
    },
    visitor: {
      ...visitorInfo,
      ...visitor,
    },
  };
  setPageData(eventData);

  eventData.event = 'pageLoad';

  window.appData.push(eventData);
};

/**
 * Sets new visitor information into pageData
 */

const setVisitor = (visitor: VisitorInfo) => {
  setPageData({
    visitor,
  });
};

const setPageData = (data: TrackingData) => {
  const { pageData = {} } = window;
  window.pageData = {
    ...pageData,
    ...data,
  };
};

/**
 * Dispatch event in Adobe Analytics
 */
export const trackEvent = (event: EventName, data: TrackingData) => {
  const { pageData, appData } = window;
  if (!pageData || !appData.length) {
    return;
  }

  if (event === 'newPage') {
    // Data passed to trackEvent() is merged with global 'pageData' object.
    // Recommended approach is to re-create it from scratch **for each newPage**
    // to make sure all dynamic properties from previous pages are cleaned up.
    const visitor = { ...visitorInfo, ...pageData.visitor };

    window.pageData = { page: { ...pageInfo }, visitor };
  }

  if (data.content && pageData.content) {
    data = {
      ...data,
      content: [
        {
          ...pageData.content[0],
          ...data.content[0],
        },
      ],
    };
  }
  data.event = event;
  window.appData.push(data);
};
