import { useState } from 'react';
import { Autocomplete, Button, defaultAutocompleteTheme, SearchInput } from '@els/biomed-ui';

import { Search } from 'assets/icons';
import { autoCompleteSuggestions } from './service/api';
import { emphasizeText } from './utils';

import styles from './AutoCompleteWrapper.module.scss';

const theme = {
  ...defaultAutocompleteTheme,
  suggestionsContainer: `${defaultAutocompleteTheme.suggestionsContainer} ${styles.underAll}`,
  suggestionHighlighted: styles.suggestionHighlighted,
  suggestion: `${defaultAutocompleteTheme.suggestion} ${styles.suggestion}`,
};

export type SuggestionsResponse = {
  synonym: string;
  useTerm: string;
  termUrn: string;
  hits: number;
  type: string;
};

export type QueryObject = {
  name: string;
  urn?: string;
};

export type Props = {
  id: string;
  query: string;
  onItemSelect: (value: QueryObject) => void;
};

const AutoCompleteContainer = ({ id, query, onItemSelect }: Props) => {
  const [value, setValue] = useState(query);

  const isDisabled = (value: string, query: string) => {
    return value.trim() === query || value.trim() === '';
  };

  const renderAutoCompleteItem = (suggestion: SuggestionsResponse) => {
    return (
      <div className={`notranslate ${styles.suggestionTermWrapper}`}>
        <div className={styles.suggestionContainer}>
          {!!suggestion.synonym && (
            <>
              <span className={styles.synonym}> {emphasizeText(suggestion.synonym, value)}</span>
              <span className='use-label'>{` use: `}</span>
            </>
          )}
          <span className={suggestion.synonym ? styles.highLightResponse : undefined}>
            {emphasizeText(suggestion.useTerm, value)}
          </span>
        </div>
        <div className={styles.suggestionHits}>{suggestion.hits.toLocaleString()}</div>
      </div>
    );
  };

  const fetcher = async (term: string = '') => {
    if (term) {
      try {
        return await autoCompleteSuggestions({ term });
      } catch (error: any) {
        console.log(error.message);
        return [];
      }
    }
    return Promise.resolve([]);
  };

  const getSuggestionValue = (suggestion: SuggestionsResponse) => suggestion.useTerm;

  return (
    <>
      <h2 className={styles.heading}>
        <b>Type in a single search Term</b> (e.g. disease,protein,drug,cell process,etc.)
      </h2>
      <div className={styles.container}>
        <Autocomplete
          className={styles.autocomplete}
          theme={theme}
          fetcher={fetcher}
          minChars={3}
          debounce={250}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={suggestion =>
            onItemSelect({ name: suggestion.useTerm.trim(), urn: suggestion.termUrn })
          }
          renderInput={({ onKeyDown, ...props }) => (
            <SearchInput
              {...props}
              className={styles.searchInput}
              data-testid={`autosuggest-input-field-${id}`}
              //In order to fix broken aria reference bug given by wave tool.
              //Issue in BIOMED library which needs to be fixed.
              aria-describedby={undefined}
              onKeyDown={e => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  if (value) onItemSelect({ name: value.trim() });
                }
                onKeyDown?.(e);
              }}
            />
          )}
          inputProps={{
            value,
            autoFocus: true,
            onChange: (value: string) => setValue(value),
          }}
          renderSuggestion={renderAutoCompleteItem}
          preventFetchOnFocus
          preventValueChangeOnKeyboardSelect
        />
        <Button
          data-testid={`autosuggest-input-button-${id}`}
          title='Search'
          iconRight={Search}
          disabled={isDisabled(value, query)}
          onClick={() => onItemSelect({ name: value.trim() })}
        >
          Search
        </Button>
      </div>
    </>
  );
};

export default AutoCompleteContainer;
