import type { QueryClientConfig } from '@tanstack/react-query';

export interface ApplicationConfig {
  defaultLanguageCode: string;
  appBaseUrl: string;
  embioLicense: string;
}

// Configuration values for our app
export const APP_CONFIG: ApplicationConfig = {
  defaultLanguageCode: 'en',
  appBaseUrl: '/api/v1',
  embioLicense: 'embio',
};

export const queryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: false, // default: 3
    },
  },
} satisfies QueryClientConfig;
