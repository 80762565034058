export const pageInfo = {
  productName: window._env.REACT_APP_AA_PRODUCT,
  businessUnit: window._env.REACT_APP_AA_BUSINESS_UNIT,
  noTracking: window._env.REACT_APP_AA_TRACKING_ENABLED !== 'true',
  environment: window._env.REACT_APP_AA_ENV,
  language: window._env.REACT_APP_AA_LOCALE || 'en',
};

export const visitorInfo = {
  accessType: `${pageInfo.productName}:ANON_GUEST`,
};

/**
 * Very naive implementation of feature switcher.
 * It can be handy to keep all code in master but still have
 * some functionality enabled only on local/dev host.
 */
