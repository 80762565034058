import { useCallback, useEffect } from 'react';
import { Placeholder, Progress } from '@els/biomed-ui';

import AppRouting from 'appRouting';
import { tokenKey } from 'constants/constant';
import Layout from 'containers/Layout';
import useAuthData from 'hooks/useAuthData';
import { session, setLocalData } from 'utils/storage';
import { initAdobeAnalytics } from 'utils/tracking';

import styles from './Bootstrap.module.scss';

const extractDomain = (email: string): string => {
  const parts: string[] = email.split('@');
  return parts[1].toLowerCase();
};

const BootstrapContainer = () => {
  const { isLoading, error, isAuthorized, isRegistered, userInfo, accessToken } = useAuthData();

  const storeToken = useCallback(() => {
    if (accessToken) {
      setLocalData(tokenKey, accessToken);
    }
  }, [accessToken]);

  const initAnalyticsInfo = useCallback(() => {
    if (userInfo) {
      const visitorInfo = {
        accessType: 'em:REG_CSCREATED',
        userId: `${window._env.REACT_APP_AA_PRODUCT}:${userInfo?.userId}`,
        accountId: `em:${userInfo?.email ? extractDomain(userInfo.email) : 'anonymous'}`,
        accountName: `em:${userInfo?.email ? extractDomain(userInfo.email) : 'anonymous'}`,
      };

      initAdobeAnalytics(visitorInfo);
    }
  }, [userInfo]);

  useEffect(() => {
    if (isRegistered) {
      initAnalyticsInfo();
      storeToken();
    }
  }, [storeToken, isRegistered, initAnalyticsInfo]);

  useEffect(() => {
    const data = session.get('search.queryData');
    if (data) session.remove('search.queryData');
  }, []);

  if (isLoading) {
    return (
      <div className={`d-sm-flex ${styles.loader}`}>
        <Progress size={'lg'} animationSpeed={'moderate'} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <Placeholder>{`Oops!! ${error.message}!! Please refresh page to login.`}</Placeholder>
      </div>
    );
  }

  return (
    <Layout>
      <AppRouting isAuthorized={isAuthorized} />
    </Layout>
  );
};

export default BootstrapContainer;
