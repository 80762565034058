import React, { useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';

import LazyLoad from 'components/LazyLoad';
import content from 'constants/content';
import useAuthData from 'hooks/useAuthData';
import CookiesPolicy from 'pages/CookiesPolicy';
import LandingPage from 'pages/LandingPage';
import Search from 'pages/Search';
import AppRoute from './AppRoute';

type Props = {
  isAuthorized?: boolean;
};

const ResultModule = React.lazy(() => import('pages/Result'));
const ReferenceModule = React.lazy(() => import('pages/Reference'));
const SavedArticlesModule = React.lazy(() => import('pages/SavedArticles'));
const GenesAnalysisModule = React.lazy(() => import('pages/GenesAnalysis'));
const NetworkModule = React.lazy(() => import('pages/Network'));

const SearchPage = () => <Search staticContent={content.searchPage} />;
const LazyResultsPage = () => (
  <LazyLoad component={ResultModule} staticContent={content.resultPage} />
);
const LazyReferencePage = () => <LazyLoad component={ReferenceModule} />;
const LazySavedArticlesPage = () => <LazyLoad component={SavedArticlesModule} />;
const LazyGenesAnalysisPage = () => <LazyLoad component={GenesAnalysisModule} />;
const LazyNetworkModule = () => <LazyLoad component={NetworkModule} />;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const AppRouting = ({ isAuthorized = false }: Props) => {
  const { signinRedirect } = useAuthData();
  const query = useQuery();
  useEffect(() => {
    const loginHint = query.get('login_hint');
    const loginConnection = query.get('connection');
    const loginOrg = query.get('organization');

    if (loginHint || loginConnection || loginOrg) {
      signinRedirect({
        login_hint: loginHint!,
        extraQueryParams: {
          connection: loginConnection!,
          organization: loginOrg!,
        },
      });
    }
  }, [signinRedirect, query]);
  return (
    <Switch>
      <AppRoute isAllowed path='/legal/cookies-policy' component={CookiesPolicy} />
      <AppRoute isAllowed path='/login' component={LandingPage} />
      <AppRoute path='/search' isAllowed={isAuthorized} component={SearchPage} />
      <AppRoute path='/result' isAllowed={isAuthorized} component={LazyResultsPage} />
      <AppRoute path='/reference' isAllowed={isAuthorized} component={LazyReferencePage} />
      <AppRoute path='/saved' isAllowed={isAuthorized} component={LazySavedArticlesPage} />
      <AppRoute path='/genesAnalysis' isAllowed={isAuthorized} component={LazyGenesAnalysisPage} />
      <AppRoute path='/networks' isAllowed={isAuthorized} component={LazyNetworkModule} />
      <Redirect to='/search' />
    </Switch>
  );
};

export default AppRouting;
