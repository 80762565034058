import store from 'store2';

const namespace = 'embiology';

export const local = store.local.namespace(namespace);

export const session = store.session.namespace(namespace);

// Utility function to set data in local storage
export const setLocalData = (key: string, value: any) => {
  local.set(key, value);
};

// Utility function to get data from local storage
export const getLocalData = (key: string) => {
  return local.get(key);
};

// Utility function to remove data from local storage
export const removeLocalData = (key: string) => {
  local.remove(key);
};

// Utility function to set data in session storage
export const setSessionData = <T>(key: string, value: T) => {
  session.set(key, value);
};

// Utility function to get data from session storage
export const getSessionData = <T>(key: string): T | null => {
  return session.get(key);
};

// Utility function to remove data from session storage
export const removeSessionData = (key: string) => {
  session.remove(key);
};
