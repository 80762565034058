import { useEffect } from 'react';

import { trackNewPage } from 'utils/tracking';

/**
 * For some pages we may want to send `newPage` event only after the data is loaded asynchronously.
 * In this case due to nature of `useEffect` hook (and inability to call hooks conditionally) it will
 * be called twice: for undefined data initially and then as soon as data will arrive from server.
 * As a solution we use agreement:
 *  - if the hook is called with single argument - send page view immediately (we assume that no data is coming)
 *  - otherwise, check for data is defined to send the event.
 */
export default function useTrackNewPage(...args: Parameters<typeof trackNewPage>) {
  useEffect(() => {
    const waitForData = args.length > 1;
    if (waitForData) {
      const [, data] = args;
      if (data) {
        trackNewPage(...args);
      }
    } else {
      trackNewPage(...args);
    }
  }, args); // eslint-disable-line react-hooks/exhaustive-deps
}
