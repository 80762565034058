// Disable ESLint rules for imports order to preserve "index.scss" import in the right place.
// eslint-disable-next-line simple-import-sort/imports
import React from 'react';
import ReactDOM from 'react-dom';

// Styles from biomed-ui MUST BE included before styles that are imported by the app components.
// Otherwise, CSS overrides will not work.
import './index.scss';

import App from './App';

console.info(
  `%c✅ ${process.env.REACT_APP_NAME} v${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_BUILD_DT} ${process.env.REACT_APP_GIT_BRANCH} ${process.env.REACT_APP_GIT_HASH})`,
  'font-weight: bold; font-size: 18px;'
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
