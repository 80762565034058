import { Modal, Popover } from '@els/biomed-ui';
import { isTestEnv } from '@els/biomed-ui/utils';

export const initBiomedUI = (appRoot = '#root') => {
  // Skip Modal initialization for tests:
  // https://github.com/reactjs/react-modal/issues/632
  if (!isTestEnv) {
    Modal.init(appRoot);
  }

  Popover.defaultProps = { animation: isTestEnv ? false : undefined };
  Modal.defaultProps = { animation: isTestEnv ? false : undefined };
};
