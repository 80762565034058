import type { VFC } from 'react';
import { NavLink } from 'react-router-dom';
import type { TestProps } from '@els/biomed-ui';

import styles from './NavItem.module.scss';

export interface Props extends TestProps {
  to: string;
  label: string;
}

const NavItem: VFC<Props> = ({ to, label, 'data-testid': testId }) => (
  <li>
    <NavLink to={to} className={styles.link} activeClassName={styles.active} data-testid={testId}>
      {label}
    </NavLink>
  </li>
);

export default NavItem;
