import { mapValues } from 'lodash';

import { APP_CONFIG } from 'App.config';

const BASE_URL = APP_CONFIG.appBaseUrl;

const restify = <T extends Record<string, string>>(urls: T): Record<keyof T, string> =>
  mapValues(urls, url => `${BASE_URL}/${url}`);

export const urlConfig = restify({
  autoCompleteUrl: 'terms/autocomplete',
  resultListUrl: 'articles',
  sankeyDataUrl: 'relations/aggregated',
  mainTermInfo: 'terms/urn',
  articlesUrl: 'articles',
  articlesFacetsUrl: 'articles/facets',
  filterOptionsUrl: 'articles/facets/entity-type',
  exportUrl: 'articles/export',
  listContentUrl: 'lists',
  filterExportUrl: 'articles/export/entity-type',
  defaultExperimentUrl: 'workbooks/experiments/default',
  uploadFileTemplate: 'resources/gene-list-template',
  experimentSSE: 'workbooks/experiments/default/status/async',
  concept: 'terms/urn',
  findConnectionQuestions: 'relations/experiments/questions',
  experimentRelationsUrl: 'relations/experiments/questions',
  conceptUrnsUrl: 'workbooks/experiments/default',
  experimentArticlesUrl: 'articles/experiment/question',
  filterOriginalTerms: 'articles/experiment/filter/originalTerms',
  exportExperimentUrl: 'relations/experiments/export/questions',

  // Network builder
  networkBuilder: 'workbooks/networks/default',
  networkBuilderSSE: 'workbooks/networks/default/status/async',
  networkTemplate: 'resources/network-builder-template',
  networkRelations: 'relations/network',
  networkArticlesUrl: 'articles/networks',
});
