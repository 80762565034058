import type { ComponentType } from 'react';
import { Redirect, Route } from 'react-router-dom';

interface Props<P> {
  path: string;
  to?: string;
  isAllowed?: boolean;
  component: ComponentType<P>;
}

const AppRoute = <P extends object>({ path, to = '/login', isAllowed, ...rest }: Props<P>) => {
  if (!isAllowed) {
    return <Redirect to={to} />;
  }

  return <Route path={path} exact {...rest} />;
};

export default AppRoute;
