import type { VFC } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FocusRing } from '@els/biomed-ui';

import { initBiomedUI } from 'biomed';
import BootstrapContainer from 'containers/Bootstrap';
import { oidcConfig } from 'KeycloakConfig';
import { queryClientConfig } from './App.config';

initBiomedUI();

const queryClient = new QueryClient(queryClientConfig);
const history = createBrowserHistory();

//TODO: Move to react-router V6.
//      Or consider @tanstack router, as we already using their react-query.
const App: VFC = () => (
  <AuthProvider {...oidcConfig}>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <FocusRing />
        <BootstrapContainer />
      </Router>
    </QueryClientProvider>
  </AuthProvider>
);

export default App;
