//TODO: unit test for this file
export const isObject = (value: any) =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

export const isArray = (value: any) =>
  typeof value === 'object' && Array.isArray(value) && value !== null;

export class UrlBuilder {
  private url: string = '';

  constructor(url: string) {
    this.setUrl(url);
  }

  getUrl = () => this.url;

  setUrl = (url: string) => {
    if (url && url.length > 0) {
      this.url = url;
      while (this.url.slice(-1) === '?' || this.url.slice(-1) === '&')
        this.url = this.url.slice(0, -1);
    }
  };

  private noParams = () => this.url.indexOf('?') < 0;

  appendParamSimple = (param: string) => {
    if (param && param.length > 0) {
      this.url += `${this.noParams() ? '?' : '&'}${param}`;
    }
  };

  appendParam = (param: string, value: any) => {
    if (param && value)
      this.appendParamSimple(
        `${encodeURIComponent(param)}=${encodeURIComponent(value.toString())}`
      );
    return this;
  };

  appendParamArray = (key: string, valueArray: any[]) => {
    if (valueArray && valueArray.length > 0) {
      const checkedValueArray = valueArray.filter(value => !!value && value !== '');
      if (checkedValueArray.length > 0) {
        checkedValueArray.forEach((value, index) => this.appendGeneric(`${key}[${index}]`, value));
      }
    }
    return this;
  };

  appendGeneric = (key: string, value: any) =>
    isObject(value)
      ? this.appendParamMap(value, key)
      : isArray(value)
        ? this.appendParamArray(key, value)
        : this.appendParam(key, value);

  appendParamMap = (paramMap?: Record<string, any>, parentClass?: string) => {
    if (paramMap) {
      const params = Object.entries(paramMap).filter(([key, value]) => key && value);
      if (params && params.length > 0) {
        const parentName = parentClass ? `${parentClass}.` : '';
        params.forEach(([key, value]) => {
          this.appendGeneric(parentName + key, value);
        });
      }
    }
    return this;
  };
}
