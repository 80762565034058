import type { FunctionComponent, ReactElement } from 'react';
import { Button, LinkButton, Popover } from '@els/biomed-ui';

import { Person } from 'assets/icons';

import styles from './HProfile.module.scss';

export type Props = {
  email: string;
  name: string;
  onSignOut: () => void;
};

const HProfile: FunctionComponent<Props> = ({ email, name, onSignOut }: Props): ReactElement => (
  <Popover
    id='header-profile'
    placement='bottom-end'
    trigger={
      <LinkButton className={styles.profileLink} flipIcon={false}>
        <span className='es-font-size-sm'> {name}</span>
        <div className={styles.iconContainer}>
          <Person className='els-header-icon' style={{ color: '#fff' }} />
        </div>
      </LinkButton>
    }
    closeButton={false}
    body={({ toggle }) => (
      <div className='els-header-profile-holder'>
        <div className={styles.profileInfo}>
          <div className={styles.iconHolder}>
            <Person className='els-header-icon' style={{ fill: '#fff' }} />
          </div>
          <div className={styles.infoHolder}>
            {name}
            {email ? <div className={styles.email}>{email}</div> : null}
          </div>
        </div>

        <Button
          id='header-btn-log-out'
          className='u-display-block els-header-full-width'
          onClick={e => {
            e.stopPropagation();
            onSignOut();
            toggle();
          }}
        >
          Log out
        </Button>
      </div>
    )}
  />
);

export default HProfile;
