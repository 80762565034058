const content = {
  searchPage: {
    searchTitle: 'Find biology related articles and networks',
    searchInputLabel: 'Type in a single search term ',
    inputPlaceHolder: 'e.g. disease, protein, drug, cell process, etc.',
  },
  resultPage: {
    advancedSearchLink: 'Advanced search',
    searchInputLabel: 'Type in a single search term ',
    inputPlaceHolder: 'e.g. disease, protein, drug, cell process, etc.',
    noResultDataText: 'No results found.',
    noQueryErrorText: 'Please enter search term to execute search.',
    noResultAdditionalInfo: 'Please check your spelling or try another query.',
    filterPanelTitle: 'Refine by:',
    selectedCountTitle: 'Total Articles',
    publicationYearsFilterTitle: 'Years',
    noSanketChartAdditionalInfo:
      'No Relation chart available for either selected combination of filters or searched query.',
    noResultForFilters: 'No Results available for selected combination of filters.',
  },
};

export default content;
