import type { VFC } from 'react';

import type { Props as NavItemProps } from './NavItem';
import NavItem from './NavItem';

import styles from './Nav.module.scss';

interface Props {
  list: NavItemProps[];
}

const Nav: VFC<Props> = ({ list }) => (
  <ul className={styles.root}>
    {list.map(item => (
      <NavItem key={item.to} {...item} />
    ))}
  </ul>
);

export default Nav;
