import type { FC } from 'react';
import { CookiesPolicy } from '@els/biomed-ui';

const { Table, Adobe, Pendo, NewRelic } = CookiesPolicy;

export const CookiesPolicyPage: FC = () => (
  <CookiesPolicy product='EmBiology'>
    <Table>
      <Adobe />
      <Pendo />
      <NewRelic />
    </Table>
  </CookiesPolicy>
);
export default CookiesPolicyPage;
