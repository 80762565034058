import { notEmpty } from '@els/biomed-ui/utils';

import type { ApiError } from 'utils/axios';
import type { Props as NavItemProps } from '../containers/Header/Nav/NavItem';
import type { ConceptTypes } from './concepts';

export type ConceptFilterTypes = ConceptTypes | 'PrimaryCellLocalization' | 'TermClass';

/**
 * UI labels for concept filters.
 */
export const conceptFilters: Record<ConceptFilterTypes, string> = {
  CellObject: 'Cell objects',
  CellProcess: 'Cell processes',
  CellType: 'Cells',
  ClinicalParameter: 'Clinical parameters',
  Complex: 'Protein complexes',
  Disease: 'Diseases',
  FunctionalClass: 'Functional classes',
  GeneticVariant: 'Genetic variants',
  Organ: 'Organs',
  Pathogen: 'Pathogens',
  Protein: 'Proteins',
  SmallMol: 'Drugs & chemicals',
  Tissue: 'Tissues',
  Treatment: 'Treatments',
  Virus: 'Viruses',
  PrimaryCellLocalization: 'Primary cell localization',
  TermClass: 'Protein classes',
};

export const filterKeyNameMapping: { [key: string]: string } = {
  ...conceptFilters,
  years: 'Publication years',
  refNum: 'References',
  direction: 'Direction',
  relationType: 'Relation Types',
  effect: 'Effect',
};

export const filterGroupKeyMapping: { [key: string]: string } = {
  publicationFilters: 'Publication filters',
  relationFilters: 'Relation filters',
  conceptFilters: 'Concept filters',
};

export const filterGroupTooltipMapping: { [key: string]: string } = {
  publicationFilters: 'Use Publication filters to adjust values',
  relationFilters: 'Click to open popup',
  conceptFilters: 'Click to open popup',
};

export const filterGroupCategoryMapping: { [key: string]: string[] } = {
  publicationFilters: ['years', 'refNum'],
  relationFilters: ['direction', 'relationType', 'effect'],
  conceptFilters: Object.keys(conceptFilters).concat('conceptType'),
};

export const headerNavLinks: NavItemProps[] = [
  {
    to: '/networks',
    label: 'Network',
    'data-testid': 'header-network',
  },

  {
    to: '/genesAnalysis',
    label: 'EmBio Insights',
    'data-testid': 'header-genes-analysis',
  },
  {
    to: '/search',
    label: 'Search',
    'data-testid': 'header-search',
  },
  {
    to: '/saved?listName=default',
    label: 'Saved lists',
    'data-testid': 'header-saved-lists',
  },
].filter(notEmpty);

export const defaultYearForSlider = 1963;
export const defaultFacetSizeRequest = 16;

export const maxExportArticles = 1000;
export const maxSelectedArticles = 1000;

export const defaultFetchErrorMessage = `Cannot connect to server, please try again later.`;

export const filterPopupPageSize = 100;
export const maxReferences = 1000;
export const maxSavedArticles = 1000;
export const maxResults = 1000;
export const maxExportLimit = 500;

export const DEFAULT_LIST = {
  id: 'default',
  name: 'My list',
};

export const maxExportedEntities = 2000;

export const tokenKey = 'auth-token';

export const supportHubUrl = 'https://service.elsevier.com/app/home/supporthub/embiology/';

export const contactUsUrl = 'https://service.elsevier.com/app/contact/supporthub/embiology/';

export const statusErrorMapping: Record<number, ApiError> = {
  400: {
    header: 'Unable to process this request',
    supportLink: false,
    message: '',
  },
  401: {
    header: 'Session timeout or Unauthorized',
    message:
      'Your session might have expired due to a long period of inactivity or you need to login. Please, sign in again.',
    supportLink: false,
    signInLink: true,
  },
  403: {
    header: 'Access denied or Forbidden',
    message:
      'Unfortunately you are not allowed to access this functionality. Please return to the homepage or contact Support if you think this is a mistake.',
  },
  404: {
    header: 'Page not found',
    message:
      "We couldn't find the page you searched for. Please check the url for any typos or misspelled words and try again.",
  },
  500: {
    header: 'Unexpected Server Error',
    message:
      'An unexpected error occurred on the server. Please try again later or contact support for assistance.',
  },
};

export const unknownError: ApiError = {
  header: 'Unknown Error',
  message: 'An unknown error occurred. Please contact support for assistance',
};

export const networkErrorMessage: ApiError = {
  header: 'You seem offline',
  message: 'It seems like your network is down. Please check your internet connection and retry.',
};

export const MAX_FILE_NO = 500;

export const GENE_UPLOAD_TEMPLATE_NAME = 'gene_list_template.xlsx';
export const NETWORK_UPLOAD_TEMPLATE_NAME = 'concepts_list_template.xlsx';

export const EXCEL_TEMPLATES = {
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

export const recentUploadsCount = 5;

export const experimentDataKey = 'experiment.listAnalysis';
export const networkDataKey = 'network.data';

export const experimentMaxConceptsCount = 200;

export const networkRecommendedConceptsCount = 15;
export const networkMaxConceptsCount = 40;
