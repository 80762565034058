import type { FunctionComponent } from 'react';
import { ToastContainer } from '@els/biomed-ui';

import Footer from 'containers/Footer';
import Header from 'containers/Header';
import { MessageBoxProvider } from 'containers/MessageBox';

import styles from './Layout.module.scss';

const Layout: FunctionComponent = ({ children }) => (
  <div className={styles.elsNbsLayout}>
    <MessageBoxProvider>
      <ToastContainer />
      <div className={styles.headerHolder}>
        <Header />
      </div>
      <div className={styles.mainContent}>{children}</div>
      <div className={styles.footerHolder}>
        <Footer />
      </div>
    </MessageBoxProvider>
  </div>
);

export default Layout;
