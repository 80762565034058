import type { VFC } from 'react';
import { BlockIconLink, List, ListItem } from '@els/biomed-ui';

import { Help } from 'assets/icons';

type Props = {
  helpRef: string;
};

const HUtils: VFC<Props> = ({ helpRef }) => (
  <List id='app-header-nav-utils' direction='row' none className='d-print-none'>
    <ListItem className='ml-4'>
      <BlockIconLink
        href={helpRef}
        target={'_blank'}
        id='header-btn-help'
        data-data-testid='embiology-help-link'
        iconLeft={Help}
        aria-label='embiology help link'
      />
    </ListItem>
  </List>
);
export default HUtils;
