import { Children } from 'react';
import { escapeRegExp } from 'lodash';

export const emphasizeText = (text: string, value: string) => {
  const regExpression = new RegExp(`(${escapeRegExp(value)})`, 'gi');
  const parts = text.split(regExpression);

  // Keys are set automatically by React
  return Children.map(parts, part => {
    if (regExpression.test(part)) {
      return <mark>{part}</mark>;
    } else {
      return part;
    }
  });
};
