import type { FunctionComponent, ReactElement } from 'react';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Grid, Row } from '@els/biomed-ui';

import type content from 'constants/content';
import type { QueryObject } from 'containers/AutocompleteWrapper';
import AutoCompleteContainer from 'containers/AutocompleteWrapper';
import useQuery from 'hooks/useQuery';
import useTrackNewPage from 'hooks/useTrackNewPage';
import { getSessionData, setSessionData } from 'utils/storage';

import styles from './Search.module.scss';

type SearchContent = typeof content.searchPage;

type Props = {
  staticContent: SearchContent;
};
const storageKey = 'search.queryData';

// TODO: fix - extract into separate file
// eslint-disable-next-line react-refresh/only-export-components
export const updateSearchTerm = (value: QueryObject) => setSessionData(storageKey, value);

// TODO: fix - extract into separate file
// eslint-disable-next-line react-refresh/only-export-components
export const getSearchTerm = () => {
  const termDetail = getSessionData(storageKey) as QueryObject | null;
  return termDetail ?? null;
};

const SearchPage: FunctionComponent<Props> = ({ staticContent }: Props): ReactElement => {
  const history = useHistory();
  const query = useQuery();
  const searchQuery = getSearchTerm()?.name || '';

  const saveAndNavigateToResult = useCallback(
    (value: QueryObject) => {
      updateSearchTerm(value);
      history.push('/result');
    },
    [history]
  );

  useEffect(() => {
    const name = query.get('name');
    const urn = query.get('urn');
    if (name && urn) {
      const queryObject: QueryObject = { name, urn };
      saveAndNavigateToResult(queryObject);
    }
  }, [query, saveAndNavigateToResult]);

  useTrackNewPage('search');

  return (
    <Grid fluid>
      <Row>
        <Col xs md={24}>
          <h1 className={`${styles.title} es-font-size-xl`}>{staticContent.searchTitle}</h1>
        </Col>
        <Col md={{ span: 12, offset: 6 }} className={styles.searchInputSection}>
          <AutoCompleteContainer
            id='search-term'
            query={searchQuery}
            onItemSelect={(value: QueryObject) => saveAndNavigateToResult(value)}
          />
        </Col>
      </Row>
    </Grid>
  );
};

export default SearchPage;
