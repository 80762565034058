import type { ComponentProps, FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';
import cn from 'classnames';
import { isFunction } from 'lodash';
import type { ButtonRendererProps, ConfirmDialogInstance } from '@els/biomed-ui';
import { Button, ConfirmDialog, Icon, Link, LinkButton } from '@els/biomed-ui';

import { Alert, Info } from 'assets/icons';
import { supportHubUrl } from 'constants/constant';
import useAuthData from 'hooks/useAuthData';
import useMounted from 'hooks/useMounted';

import styles from './MessageBox.module.scss';

export type MessageBoxType = 'info' | 'error' | 'attention';
export type MessageBoxActionRenderer = string | false | ((props: ButtonProps) => ReactElement);

type ActionHandler = (instance: ConfirmDialogInstance) => unknown | Promise<unknown>;
type ButtonProps = ComponentProps<typeof Button>;
type ConfirmDialogProps = ComponentProps<typeof ConfirmDialog>;

// Base props for modal customization.
export interface BaseMessageBoxProps
  extends Pick<ConfirmDialogProps, 'id' | 'header' | 'fontSize' | 'className' | 'style'> {
  confirm?: MessageBoxActionRenderer;
  alternative?: MessageBoxActionRenderer;
  cancel?: MessageBoxActionRenderer;
  supportLink?: boolean;
  signInLink?: boolean;
}

const defaultProps: BaseMessageBoxProps = {
  confirm: 'Ok',
  alternative: false,
  cancel: false,
};

const defaultPropsPerType: Record<MessageBoxType, BaseMessageBoxProps> = {
  error: { header: 'Error' },
  info: { header: 'Information' },
  attention: { header: 'Attention' },
};

interface Props extends BaseMessageBoxProps, Pick<ConfirmDialogProps, 'onClose'> {
  type?: MessageBoxType;
  message?: ReactNode;
  onConfirm?: ActionHandler;
  onAlternative?: ActionHandler;
}

export const MessageBox: FC<Props> = ({
  type = 'error',
  message,
  supportLink = true,
  signInLink = false,
  fontSize = 'sm',
  ...props
}) => {
  const { header, confirm, alternative, cancel, onConfirm, onAlternative, onClose, ...otherProps } =
    applyDefaults(props, type);

  const { signinRedirect } = useAuthData();

  const [submitting, setSubmitting] = useState(false);
  const isMounted = useMounted();

  const createHandler =
    (handleFromProps?: ActionHandler) => async (instance: ConfirmDialogInstance) => {
      setSubmitting(true);
      try {
        await handleFromProps?.(instance);
      } finally {
        // When using via `MessageBoxProvider` the `api.hide` is called (=modal is closed)
        // right before resetting submitting state, which causes the warning in dev console.
        if (isMounted()) {
          setSubmitting(false);
        }
      }
    };

  // Header icon rendering based on MessageBoxType
  const headerElement = (
    <div className={styles.errorHeaderContainer}>
      <div className={styles.errorHeader}>
        <Icon
          name={type === 'info' ? Info : Alert}
          height={22}
          width={24}
          className={styles.alertIcon}
        />
        <span className={cn('ml-1', styles.title)}>{header}</span>
      </div>
      {supportLink && (
        <Link href={supportHubUrl} external colored onClick={onClose}>
          Support
        </Link>
      )}
      {signInLink && (
        <LinkButton
          colored
          onClick={e => {
            e.stopPropagation();
            signinRedirect();
            onClose();
          }}
        >
          Sign in
        </LinkButton>
      )}
    </div>
  );

  return (
    <ConfirmDialog
      isOpen={!!message}
      data-testId={`${type}-message-box`}
      header={headerElement}
      fontSize={fontSize}
      confirm={buttonRenderer(confirm, {
        disabled: submitting,
        className: cn(styles.confirm, styles[type]),
      })}
      alternative={
        buttonRenderer(alternative, {
          variant: 'quaternary',
          disabled: submitting,
        }) || undefined
      }
      cancel={buttonRenderer(cancel, {
        variant: 'quaternary',
        disabled: submitting,
      })}
      onConfirm={createHandler(onConfirm)}
      onAlternative={createHandler(onAlternative)}
      onClose={onClose}
      reverseButtons
      // Default modal z-index is 99.
      style={{ overlay: { zIndex: 110 }, content: { maxWidth: '30em' } }}
      {...otherProps}
    >
      <p className='ml-7'>{message}</p>
    </ConfirmDialog>
  );
};

const applyDefaults = <T extends BaseMessageBoxProps>(props: T, theme: MessageBoxType): T => ({
  ...defaultProps,
  ...defaultPropsPerType[theme],
  ...props,
});

const buttonRenderer = (
  renderer?: MessageBoxActionRenderer,
  { variant = 'primary', ...buttonProps }: ButtonProps = {}
) => {
  if (!renderer) {
    // Important to return falsy as is because false/undefined has different meaning inside the `ConfirmDialog`
    return renderer;
  }

  // This renderer will apply both mandatory props that come from ConfirmDialog and
  // other custom button props.
  return (props: ButtonRendererProps) => {
    const className = cn(props.className, buttonProps.className);
    const finalProps = { variant, ...props, ...buttonProps, className };

    return isFunction(renderer) ? (
      renderer(finalProps)
    ) : (
      <Button {...finalProps}>{renderer}</Button>
    );
  };
};
