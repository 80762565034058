import type { ComponentType } from 'react';
import { Suspense } from 'react';
import { Progress } from '@els/biomed-ui';

type Props<P> = P & {
  component: ComponentType<P>;
};

const LazyLoad = <P extends object>({ component: Component, ...rest }: Props<P>) => (
  <Suspense
    fallback={
      <div className='u-margin-xl-ver d-flex justify-content-center'>
        <Progress className='u-margin-xl-ver' size='lg' animationSpeed='moderate' />
      </div>
    }
  >
    <Component {...(rest as P)} />
  </Suspense>
);

export default LazyLoad;
